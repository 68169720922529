import React from "react";
import MenuTabs from "@components/Admin/MenuTabs";

const ClientMenu: React.FC = () => (
  <MenuTabs
    items={[
      {
        id: "ClientUserClientUserNavigationTitle",
        path: "/admin/clientusers",
        page: "ClientUsers",
        subpage: "ClientUsers",
      },
    ]}
  />
);

export default ClientMenu;
